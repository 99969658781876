import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Main from "../Main/Main";
import Calculate from "../Calculate/Calculate";

export default function Layout(props) {
  const {
    modalState,
    setModalState,
    onCalculate,
    children
  } = props;

  return (
    <>
      <Header modalState={modalState} setModalState={setModalState} />
      <Calculate layoutProps={props} onCalculate={onCalculate} />
      <Main>{children}</Main>
      <Footer modalState={modalState} setModalState={setModalState} />
    </>
  );
}
