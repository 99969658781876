import React from "react";
import styles from "./Modal.module.scss";
import cn from "classnames";
import Button from "../Button/button";
import { ReactComponent as XIcon } from "../../styles/icons/x.svg";
import axios from "axios";

export const ModalState = {
  CLOSED: 0,
  FORM: 1,
  SENT: 2
}

export default function Modal({ modalState, setModalState }) {
  function onSubmit(e) {
    e.preventDefault();

    axios.postForm("/api.php?method=sendForm", {
      name: e.target.name.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
      referer: window.location.pathname + window.location.search
    }).then(() => {
      setModalState(ModalState.SENT)
    })
  }

  return (
    <div
      className={`${styles.wrapper} ${modalState !== ModalState.CLOSED ? styles.open : styles.close}`}
      onClick={() => setModalState(ModalState.CLOSED)}
    >
      <div className={cn(styles.content)} onClick={e => e.stopPropagation()}>
        <div className={cn(styles.header)}>
          <h2 className={cn(styles.title)}>{modalState === ModalState.FORM ? "Отправить заявку" : "Заявка отправлена"}</h2>
          <XIcon onClick={() => setModalState(ModalState.CLOSED)} />
        </div>
        {modalState === ModalState.FORM ? (
          <form className={cn(styles.form)} onSubmit={onSubmit}>
            <input placeholder="Имя" name="name" className={cn(styles.input)} />
            <input placeholder="Телефон" name="phone" className={cn(styles.input)} />
            <textarea placeholder="Комментарий" name="message" className={cn(styles.comment)} />
            <Button
              buttonType={"primary"}
              title="ОТПРАВИТЬ"
              style={{ width: window.innerWidth > 500 ? "384px" : "312px" }}
            ></Button>
            <p className={styles.subtitle}>
              Нажатием на кнопку "Отправить" я даю свое согласие на обработку
              персональных данных в соответствии с указанными здесь условиями.
            </p>
          </form>
        ) : (
          <>
            <p className={styles.text}>
              Спасибо за обращение. В ближайщее время с Вами свяжется наш специалист
            </p>
            <a href="/" className={styles.closeLink} onClick={(e) => {e.preventDefault(); setModalState(ModalState.CLOSED)}}>Закрыть</a>
          </>
        )}
      </div>
    </div>
  );
}
