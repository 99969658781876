import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import styles from "../App/App.module.scss";
import Products from "../Products/Products";
import Buttons from "../Buttons/Buttons";
import Examples from "../Examples/Examples";
import Socials from "../Socials/Socials";
import Modal, { ModalState } from "../Modal/Modal";
import { useSearchParams } from 'react-router-dom';

function renderJoin(nodes, separator) {
  return nodes.flatMap((node, idx) =>
      idx < nodes.length - 1 ? [node, separator] : [node]
  ).map((node, idx) => <React.Fragment key={idx}>{node}</React.Fragment>);
}

function renderBoldAsterisks(text) {
  const parts = text.split(/\*\*(.*?)\*\*/g);

  var result = "";

  for(let i = 0; i < parts.length; i++) {
    if(i % 2 === 1) {
      result = <>{result}<b key={i}>{parts[i]}</b></>
    } else {
      result = <>{result}<React.Fragment key={i}>{parts[i]}</React.Fragment></>
    }
  }

  return result;
}

function DynamicText(variableNames) {
  [this.template, this.setTemplate] = React.useState("");
  [this.text, this.setText] = React.useState("");

  [this.variables, this.setVariables] = React.useState(() => {
    const initialState = {};
    for(const name of variableNames)
      initialState[name] = "";
    return initialState
  });

  const that = this;
  
  this.updateVariable = (key, value) => {
    this.setVariables(variables => ({
      ...variables,
      [key]: value
    }))
  }

  useEffect(
    () => {
      if(!that.template) return;

      var text = that.template+"";
      for(const [key, value] of Object.entries(that.variables)) {
        text = text.replaceAll(`{{${key}}}`, value);
      }

      that.setText(
        renderJoin(
          text.split("\n")
            .map(line => renderBoldAsterisks(line)),
          <br/>
        )
      );
    },
    [
      that.template,
      that.variables,
    ]
  )
}

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [modalState, setModalState] = React.useState(ModalState.CLOSED);

  const [calculatedData, setCalculatedData] = React.useState({});

  const appData = window.__APP_DATA__;

  function updateText(squareOption_, thicknessOption_, brandOption_) {
    titleDynamicText.updateVariable("square", squareOption_.label);
    subtitleDynamicText.updateVariable("brandSubtitle", appData.texts[`${brandOption_.value}_brandSubtitle`]);
    subtitleDynamicText.updateVariable("thickness", thicknessOption_.label);
  }

  function updateCalculator(squareOption_, thicknessOption_, brandOption_) {
    let square = squareOption_.value;
    let thickness = thicknessOption_.value;
    let brand = brandOption_.value;
    
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      "square": square,
      "thickness": thickness,
      "brand": brand
    });

    let products = appData.products
      .filter(product =>
        product[square] !== "no" &&
        product[thickness] !== "no" &&
        product[brand] !== "no"
      ).map(product => ({
        img: product.img,
        name: product.title,
        price: product.price,
        qnt: product[square],
        unit: product.unit,
      }));

    let jobs = appData.jobs
      .filter(job =>
        job[square] !== "no" &&
        job[thickness] !== "no" &&
        job[brand] !== "no"
      ).map(job => ({
          name: job.title,
          price: job.price,
          qnt: job[square],
          unit: job.unit,
      }))

    setCalculatedData({
      products: products,
      jobs: jobs
    });
  }

  const titleDynamicText = new DynamicText(["square"]);
  const subtitleDynamicText = new DynamicText(["brandSubtitle", "thickness"]);

  let square = searchParams.get("square") || null;
  let thickness = searchParams.get("thickness") || null;
  let brand = searchParams.get("brand") || null;

  let squareOption_;
  let thicknessOption_;
  let brandOption_;

  found: {
    for(const option of appData.options.square) {
      if(option.value === square) {
        squareOption_ = option; break found;
      } }
      squareOption_ = appData.options.square[0];
  }

  found: {
    for(const option of appData.options.thickness) {
      if(option.value === thickness) {
        thicknessOption_ = option; break found;
      } }
      thicknessOption_ = appData.options.thickness[0];
  }

  found: {
    for(const option of appData.options.brand) {
      if(option.value === brand) {
        brandOption_ = option; break found;
      } }
      brandOption_ = appData.options.brand[0];
  }

  const [squareOption, setSquareOption] = React.useState(squareOption_);
  const [thicknessOption, setThicknessOption] = React.useState(thicknessOption_);
  const [brandOption, setBrandOption] = React.useState(brandOption_);

  React.useMemo(() => {
    titleDynamicText.setTemplate(window.__APP_DATA__.texts.titleText);
    subtitleDynamicText.setTemplate(window.__APP_DATA__.texts.subtitleText);
    
    updateText(squareOption_, thicknessOption_, brandOption_);

    updateCalculator(squareOption_, thicknessOption_, brandOption_);
  }, []);

  return (
    <>
      <Layout
        modalState={modalState} setModalState={setModalState}
        squareOption={squareOption} setSquareOption={setSquareOption}
        insulationOption={thicknessOption} setInsulationOption={setThicknessOption}
        brandOption={brandOption} setBrandOption={setBrandOption}
        onCalculate={()=>0}
        appData={appData}
      >
        <h1 className={styles.title}>{titleDynamicText.text}</h1>
        <p className={styles.subtitle}>{subtitleDynamicText.text}</p>
        <Products data={calculatedData.products || []} />
        <Products
          data={calculatedData.jobs || []}
          m_header="Если вам необходим монтаж"
          title="Если вам необходим монтаж:"
        />

        <Buttons modalState={modalState} setModalState={setModalState} />

        <Examples />
        <Socials />
      </Layout>
      <Modal modalState={modalState} setModalState={setModalState} />
    </>
  );
}
