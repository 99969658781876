import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/button";
import styles from "./Calculate.module.scss";


export default function Calculate({
  layoutProps,
  onCalculate
}) {
  // function onSubmit(e) {
  //   e.preventDefault();
  //   return onCalculate();
  // }

  return (
    <form
      method="get"
      action=""
      className={styles.container}
      // onSubmit={onSubmit}
    >
      <div
        className={styles.mobileTitle}
      >
        Расчет водяного теплого пола
      </div>
      <Dropdown
        title="Площадь теплого пола: "
        options={layoutProps.appData ? layoutProps.appData.options.square : []}
        queryKey="square"
        selected={layoutProps.squareOption} setSelected={layoutProps.setSquareOption}
      />
      <Dropdown
        title="Толщина утелителя:"
        options={layoutProps.appData ? layoutProps.appData.options.thickness : []}
        queryKey="thickness"
        selected={layoutProps.insulationOption} setSelected={layoutProps.setInsulationOption}
      />
      <Dropdown
        title="Производитель трубы:"
        options={layoutProps.appData ? layoutProps.appData.options.brand : []}
        queryKey="brand"
        selected={layoutProps.brandOption} setSelected={layoutProps.setBrandOption}
        wpx={"325px"}
      />
      <Button
        buttonType="primary"
        title="Рассчитать"
        style={{ width: "179px", alignSelf: "end" }}
      />
    </form>
  );
}
