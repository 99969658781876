import React, { useEffect, useState } from "react";
import styles from "./Dropdown.module.scss";
import { ReactComponent as Arrow } from "../../styles/icons/down.svg";
import cn from "classnames";
import { useSearchParams } from 'react-router-dom';

export default function Dropdown({
  title,
  wpx = "210px",
  options,
  queryKey,
  selected, setSelected
}) {
  const [isActive, setIsActive] = React.useState(false);

  return (
    <div className={styles.dropdown} style={{ width: wpx }}>
      <p className={styles.title}>{title}</p>
      <div
        className={styles.dropdownBtn}
        onClick={() => setIsActive(!isActive)}
      >
        {selected ? selected.label : options.length ? options[0].label : ""}

        <Arrow />
      </div>

      <div
        className={cn(styles.dropdownContent, styles.noneShow, {
          [styles.show]: isActive,
        })}
      >
        {options?.map(option => (
          <label 
          key={option.label} 
          className={cn(styles.dropdownItem, styles.active)}
          onClick={e => {
            setSelected(option);
            setIsActive(false);
          }}
          >
            <input
              id={option.value}
              type="radio"
              name={queryKey}
              value={option.value}
              defaultChecked={(selected ? selected.value : options[0].value) === option.value}
            />
            <span
              htmlFor={option.value}
            >
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
}
