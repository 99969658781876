import React from "react";
import styles from "./Examples.module.scss";
import ImageViewer from "react-simple-image-viewer";

export default function Examples({
  title = "Примеры монтажа водяного теплого пола по смете:",
  subTitle = "Смета водяного теплого пола с оптимальными параметрами: на 4 контура, диаметр трубы 16мм, шаг укладки 20 см, подводка от расстояние от коллектора до границы отапливаемой площади 5 м.",
}) {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const images = [
    { url: "/assets/images/1", ext: "jpg" },
    { url: "/assets/images/2", ext: "jpg", small: true },
    { url: "/assets/images/3", ext: "jpg" },
    { url: "/assets/images/4", ext: "jpg" },
    { url: "/assets/images/5", ext: "jpg" },
    { url: "/assets/images/6", ext: "jpg" },
    { url: "/assets/images/7", ext: "jpg" },
    { url: "/assets/images/8", ext: "jpg" },
    { url: "/assets/images/9", ext: "jpg" },
    { url: "/assets/images/10", ext: "jpg" },
    { url: "/assets/images/11", ext: "jpg" },
    { url: "/assets/images/12", ext: "jpg" },
    { url: "/assets/images/13", ext: "jpg" },
    { url: "/assets/images/14", ext: "jpg" },
    { url: "/assets/images/15", ext: "jpg" },
    { url: "/assets/images/16", ext: "jpg" },
    { url: "/assets/images/17", ext: "jpg" },
    { url: "/assets/images/18", ext: "jpg" },
    { url: "/assets/images/19", ext: "jpg" },
    { url: "/assets/images/20", ext: "jpg" },
    { url: "/assets/images/21", ext: "jpg" },
    { url: "/assets/images/22", ext: "jpg" },
    { url: "/assets/images/23", ext: "jpg" },
    { url: "/assets/images/24", ext: "jpg" },
  ];

  const images2 = [
    { url: "/assets/images/1", ext: "jpg" },
    { url: "/assets/images/2", ext: "jpg" },
    { url: "/assets/images/3", ext: "jpg" },
    { url: "/assets/images/4", ext: "jpg" },
    { url: "/assets/images/5", ext: "jpg" },
    { url: "/assets/images/6", ext: "jpg" },
    { url: "/assets/images/7", ext: "jpg" },
    { url: "/assets/images/8", ext: "jpg" },
  ];

  const getFullSizeImages = (images) => {
    return images.map(item => `${item.url}.${item.ext}`)
  }

  const getSmallSizeImages = (images) => {
    return images.map(item => {
      let smallName = '_small'
      if ('small' in item && !item.small) {
        smallName = ''
      }
      return `${item.url}${smallName}.${item.ext}`
    })
  }

  const openImageViewer = React.useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  // eslint-disable-next-line
  const [mobile, setMobile] = React.useState(window.innerWidth);

  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <p>{subTitle}</p>
      <div className={styles.content}>
        {mobile <= 500
          ? getSmallSizeImages(images2).map((item, i) => (
              <img
                key={i}
                className={styles.item}
                src={item}
                onClick={() => openImageViewer(i)}
                alt="item"
              />
            ))
          : getSmallSizeImages(images).map((item, i) => (
              <img
                key={i}
                className={styles.item}
                src={item}
                onClick={() => openImageViewer(i)}
                alt="item"
              />
            ))}
        {mobile <= 500
          ? isViewerOpen && (
              <ImageViewer
                src={getFullSizeImages(images2)}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )
          : isViewerOpen && (
              <ImageViewer
                src={getFullSizeImages(images)}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
      </div>
    </div>
  );
}
